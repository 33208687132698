import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/body/dry-skin/aquaphor-ointment-bodyspray-front.webp";
import backImage from "images/body/dry-skin/aquaphor-ointment-bodyspray-back.webp";
import frontZoomImage from "images/body/dry-skin/aquaphor-ointment-bodyspray-front-zoom.webp";
import backZoomImage from "images/body/dry-skin/aquaphor-ointment-bodyspray-back-zoom.webp";

import img2 from "images/body/dry-skin/chart-aquaphor-body-spray-data.webp";
import img3 from "images/body/dry-skin/chart-aquaphor-body-spray-tolerability-data.webp";
import img4 from "images/body/dry-skin/chart-aquaphor-body-spray-application.webp";
import img5 from "images/callouts/cout-footer-product-samples.webp";
import img6 from "images/callouts/cout-footer-advanced-hydration.webp";

function DrySkinAquaphorBodySprayPage({ pageContext }) {
  useGoToAnchor();

  return (
    <Layout
      pageClass="page-body-dryskin page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Aquaphor Oinment Body Spray"
        pageDescription="Learn about Aquaphor Ointment Body Spray"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#003E7E"
              categoryName="DRY, ROUGH SKIN"
              categoryFontColor="#ffffff"
              productName="Aquaphor<sup>®</sup> Ointment Body Spray"
              productSlider={[frontImage, backImage]}
              zoomImages={[frontZoomImage, backZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold blue-text sub-title">
                  All-over relief in a convenient spray
                </p>
                <p>
                  <span className="font-semi-bold">
                    Aquaphor Ointment Body Spray
                  </span>{" "}
                  is an easy-to-apply solution for all-over treatment of dry,
                  rough skin. It sprays upside down for convenient coverage of
                  large, hard-to-reach areas.
                </p>
                <ul className="blue-bullets">
                  <li>
                    <span className="font-semi-bold">
                      Improvement in dry, rough skin in just 2 weeks
                    </span>
                  </li>
                  <li>
                    <span>
                      Ideal for larger areas on the torso, back, arms, and legs
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold blue-text sub-title">
                  Key ingredients
                </p>
                <Row>
                  <Col sm={12} md={6}>
                    <p className="font-semi-bold">Petrolatum</p>
                    <ul className="blue-bullets">
                      <li>
                        <span>Locks in moisture</span>
                      </li>
                    </ul>
                    <p className="font-semi-bold">Panthenol</p>
                    <ul className="blue-bullets">
                      <li>
                        <span>Softens and conditions skin</span>
                      </li>
                      <li>
                        <span>Binds water to support moisture</span>
                      </li>
                    </ul>
                    <p className="font-semi-bold">Lanolin alcohol</p>
                    <ul className="blue-bullets">
                      <li>
                        <span>Supports barrier repair</span>
                      </li>
                      <li>
                        <span>Defends against moisture loss</span>
                      </li>
                    </ul>
                  </Col>
                  <Col sm={12} md={6}>
                    <p className="font-semi-bold">Glycerin</p>
                    <ul className="blue-bullets">
                      <li>
                        <span>Binds water</span>
                      </li>
                      <li>
                        <span>Replenishes moisture reservoir</span>
                      </li>
                    </ul>
                    <p className="font-semi-bold">Bisabolol</p>
                    <ul className="blue-bullets">
                      <li>
                        <span>Soothes dry, irritated skin</span>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold blue-text sub-title">
                    Formulated for dry, rough skin
                  </p>
                  <ul className="checkmarks blue">
                    <li>
                      <span>hypoallergenic</span>
                    </li>
                    <li>
                      <span>non-comedogenic</span>
                    </li>
                    <li>
                      <span>preservative-free</span>
                    </li>
                    <li>
                      <span>Fragrance-free</span>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </section>
        </div>
        <div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="DRY, ROUGH SKIN"
              productThemeColor="eucerin-blue"
              id="dryroughskin"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img2}
                    className="accordion-chart"
                    alt="Patient with improvements chart after using Aquaphor Ointment Body Spray"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> Assessment
                    of Aquaphor Ointment Body Spray skin compatibility and
                    efficacy in 80 patients with dry or very dry skin
                    conditions. Daily application, mean usage 16.2 days.
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Improvement in dry, <br className="show-desktop" />
                    rough skin in just <br className="show-desktop" />2 weeks
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="DRY SKIN IRRITATION"
              productThemeColor="eucerin-blue"
              id="dryskinirritation"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img3}
                    className="accordion-chart"
                    alt="Percentage of patients with improved tolerability parameters"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> Assessment
                    of Aquaphor Ointment Body Spray skin compatibility and
                    efficacy in 80 patients with dry or very dry skin
                    conditions. Daily application, mean usage 16.2 days.
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Improvement in
                    <br className="show-desktop" /> redness, itch, and
                    <br className="show-desktop" /> dryness in just
                    <br className="show-desktop" /> 2 weeks
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="APPLICATION"
              productThemeColor="eucerin-blue"
              id="application"
            >
              <Row>
                <Col sm={12} md={8}>
                  <img
                    src={img4}
                    className="accordion-chart"
                    alt="Application to use Aquaphor Ointment Spray"
                  />
                  <p className="footnotes">
                    For best results, can should be held 3-5 inches from
                    targeted area.
                    <br />
                    Cover floor with towel before applying. Do not inhale the
                    mist.{" "}
                    <span className="font-bold">
                      DO NOT spray or use on the face.
                    </span>
                  </p>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img6}
                  imgAlt="Eucerin® Advanced Repair Cream"
                  caption="Offer patients advanced<br class='show-desktop' /> hydration for dry skin"
                  buttonText="Eucerin<sup>®</sup> Advanced Repair Cream"
                  buttonClass="long"
                  buttonUrl="/body/dryskin/eucerinadvancedrepaircream"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img5}
                  imgAlt="Aquaphor Healing Ointment®"
                  caption="Helps from head to toe—<br class='show-desktop' /> what are your favorite uses?"
                  buttonText="Aquaphor Healing Ointment<sup>®</sup>"
                  buttonUrl="/body/damagedskin"
                  imgStyles={{ height: 165 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default DrySkinAquaphorBodySprayPage;
